<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="card card-default animated-fast fadeInDown" :class="loading ? 'whirl' : ''" style="max-width: 850px; margin: auto">
                <div class="card-header">
                    Shop Payment Confirmation
                </div>

                <div class="card-body p-0" style="background: white">

                    <b-row>
                        <b-col md="7">
                            <div class="p-3">
                                <table class="table">
                                    <tr>
                                        <td><b><i class="fad fa-user mr-2"></i> Customer:</b></td>
                                        <td>{{ customer.firstname }} {{ customer.lastname }}</td>
                                    </tr>
                                    <tr>
                                        <td><b><i class="fad fa-file-invoice mr-2"></i> Order:</b></td>
                                        <td>{{ order.ref }}</td>
                                    </tr>
                                    <tr>
                                        <td><b><i class="fad fa-money-bill mr-2"></i> Amount:</b></td>
                                        <td>{{ order.amount_original | fiat }} {{ order.currency }}</td>
                                    </tr>
                                </table>

                                <hr/>

                                <b><i class="fad fa-truck-loading mr-2"></i> Delivery Address:</b>

                                <address class="border p-3 mt-1" style="max-width: 300px;">
                                    {{delivery_address.firstname}} {{delivery_address.lastname}}<br/>
                                    {{delivery_address.address1}}<br/>
                                    {{delivery_address.address2}}<br/>
                                    {{delivery_address.postcode}} {{delivery_address.city}}
                                </address>
                            </div>
                        </b-col>
                        <b-col md="5" class="bg-primary pt-3">
                            <div class="p-3">
                                <template v-if="order.currency !== 'CBD'">
                                    <div class="card card-default card-stat mb-3">
                                        <div class="card-header">
                                            {{ $t('wallet.component.balance_fiat.title', {currency: 'EUR'}) }}
                                            <i class="card-header-icon cai-cash"></i>
                                        </div>
                                        <div class="card-body p-0 text-right pr-2">
                                            <formatted-number :amount="balances.fiat.amount" type="fiat" animated=""/>

                                            <div class="text-danger">
                                                -
                                                <formatted-number :amount="order.amount_fiat" type="fiat" animated=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <b-btn @click="pay('eur')" :disabled="balances.fiat.amount < order.amount_fiat">Pay with EUR <i class="fad fa-arrow-right ml-2"></i></b-btn>
                                    </div>
                                </template>

                                <div class="card card-default card-stat mb-3 mt-3">
                                    <div class="card-header">
                                        {{ $t('wallet.component.balance_cannabis.title') }}<i class="card-header-icon fad fa-boxes-alt"></i>
                                    </div>
                                    <div class="card-body p-0 text-right pr-2">
                                        <formatted-number :amount="balances.cbd.amount" type="cannabis" animated=""/>

                                        <div class="text-danger">
                                            -
                                            <formatted-number :amount="order.amount_cbd" type="cannabis" animated=""/>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <b-btn @click="pay('cbd')" variant="secondary" :disabled="balances.cbd.amount < order.amount_cbd">Pay with CBD <i class="fad fa-arrow-right ml-2"></i></b-btn>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                loading: true,
                order: {
                    ref: 'Loading ...',
                    currency: 'EUR',
                    amount_original: 0,
                    amount_fiat: 0,
                    amount_cbd: 0,
                },
                redirect: '',
                exchangeRates: {
                    cbd: 4.5,
                },
                customer: {
                    firstname: 'Loading ...',
                    lastname: ''
                },
                delivery_address: {
                    firstname: 'Loading ...',
                    lastname: '',
                    address1: '',
                    address2: '',
                    postcode: '',
                    city: '',
                },
                balances: {
                    fiat: {
                        available: 0
                    },
                    cbd: {
                        available: 0
                    }
                }
            }
        },
        beforeCreate() {
            this.$api.get('shop/order-payment/' + this.$route.params.ref).then(response => {
                this.loading = false;
                this.balances = response.data.balances;
                this.order = response.data.order;
                this.customer = response.data.customer;
                this.delivery_address = response.data.delivery_address;
                this.redirect = response.data.redirect;
            }).catch(response => {
                this.$swal.fire('Failed', response.data.message);
              //  this.$router.push({name: 'Dashboard'});
            });
        },
        methods: {
            pay(type) {
                this.loading = true;
                this.$auth.requestVerfificationCode().then(code => {
                    return this.$api.post('shop/order-payment/' + this.$route.params.ref, {
                        currency: type,
                        verification_code: code.verification_code
                    })
                }).then(() => {
                    window.location.href = this.redirect
                }).catch(response => {
                    this.loading = false;
                    this.$swal.fire('Failed', response.data.message);
                })
            }
        },
    }
</script>